body {
  margin: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Apply the font to an element */
body {
  font-family: 'Poppins', sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
